// src/components/Navbar.js
import React from 'react';
import "../styles/Navbar.css";

// Importing Material-UI icons
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import CreateIcon from '@mui/icons-material/Create';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="nav-left">
        <div className="logo">Ashar Ahmed</div>
        <ul className="nav-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#experience">Experience</a></li>
          <li><a href="#projects">Projects</a></li>
        </ul>
      </div>
      <div className="social-icons">
        <a href="mailto:youremail@example.com">
          <EmailRoundedIcon style={{ fontSize: 24 }} />
        </a>
        <a href="https://github.com/yourgithub" target="_blank" rel="noreferrer">
          <GitHubIcon style={{ fontSize: 24 }} />
        </a>
        <a href="https://linkedin.com/in/yourlinkedin" target="_blank" rel="noreferrer">
          <LinkedInIcon style={{ fontSize: 24 }} />
        </a>
        <a href="#contact">
          <CreateIcon style={{ fontSize: 24 }} />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
