// src/components/Hero.js
import React from 'react';
import Typewriter from 'typewriter-effect';
import "../styles/Hero.css";
import ThreeJsAnimation from './ThreeJsAnimation';

const Hero = () => {
    return (
      <section className="hero">
        <ThreeJsAnimation />
        <div className="hero-text">
          <h1>
            hi, <span className="accent">Ashar</span> here.
            <span className="typewriter">
              <Typewriter
                options={{
                  strings: [''], // No additional strings to type
                  autoStart: true,
                  loop: true,
                  cursor: '|', // Vertical blinking line cursor
                  delay: 200, // Delay between blinks
                }}
              />
            </span>
          </h1>
          <p>I build things.</p>
          <button className="cta-button">Say Hello</button>
        </div>
      </section>
    );
  };
  
  export default Hero;