// src/App.js
import React from 'react';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import ThreeJsAnimation from './components/ThreeJsAnimation';
import './App.css';

const App = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <ThreeJsAnimation />
    </>
  );
};

export default App;
