// src/components/ThreeJsAnimation.js
import React, { useEffect } from 'react';
import * as THREE from 'three';

const ThreeJsAnimation = () => {
  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById('three-js-animation').appendChild(renderer.domElement);

    // Example geometry
    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: '#7eb700', wireframe: true });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    camera.position.z = 5;

    // Animation loop
    const animate = function () {
      requestAnimationFrame(animate);
      sphere.rotation.x += 0.01;
      sphere.rotation.y += 0.01;
      renderer.render(scene, camera);
    };

    animate();

    // Cleanup on component unmount
    return () => {
      while (document.getElementById('three-js-animation').firstChild) {
        document.getElementById('three-js-animation').removeChild(document.getElementById('three-js-animation').firstChild);
      }
    };
  }, []);

  return <div id="three-js-animation" style={{ height: '100vh', position: 'absolute', top: 0, left: 0, zIndex: -1 }}></div>;
};

export default ThreeJsAnimation;
